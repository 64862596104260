const state = {
    barColor: '#7A1878, #7A1878',
    barImage: '',
    drawer: null,
    itemsPerPage: 25,
}

const mutations = {
    SET_BAR_IMAGE (state, payload) {
        state.barImage = payload
    },
    SET_DRAWER (state, payload) {
        state.drawer = payload
    },
}

const getters = {}

const actions = {}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}
