import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'

Vue.use(Router)

// hhh

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      name: 'Login',
      path: '/Login',
      component: () => import('@/views/dashboard/Login'),
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
        },

        // Penyewa
        {
          name: 'Penyewa',
          path: 'penyewa',
          component: () => import('@/views/dashboard/pages/penyewa/Penyewa.vue'),
        },
        {
          name: 'PenyewaDetail',
          path: 'penyewa/detail/:id?',
          component: () => import('@/views/dashboard/pages/penyewa/PenyewaDetail.vue'),
        },
        {
          name: 'PenyewaPerusahaanDetail',
          path: 'penyewa/perusahaan/detail/:id?',
          component: () => import('@/views/dashboard/pages/penyewa/PenyewaPerusahaanDetail.vue'),
        },

        // Mitra
        {
          name: 'Mitra',
          path: 'mitra',
          component: () => import('@/views/dashboard/pages/mitra/Mitra.vue'),
        },
        {
          name: 'MitraDetail',
          path: 'mitra/detail/:id?',
          component: () => import('@/views/dashboard/pages/mitra/MitraDetail.vue'),
        },
        {
          name: 'MitraPerusahaanDetail',
          path: 'mitra/perusahaan/detail/:id?',
          component: () => import('@/views/dashboard/pages/mitra/MitraPerusahaanDetail.vue'),
        },

        // Vendor
        {
          name: 'Vendor',
          path: 'vendor',
          component: () => import('@/views/dashboard/pages/vendor/Vendor.vue'),
        },

        // Commission
        {
          name: 'Commission',
          path: 'commission',
          component: () => import('@/views/dashboard/pages/commission/Commission.vue'),
        },

        // Aset
        {
          name: 'Aset',
          path: 'aset',
          component: () => import('@/views/dashboard/pages/aset/Aset.vue'),
        },
        {
          name: 'AsetDetail',
          path: 'aset/detail/:id?',
          component: () => import('@/views/dashboard/pages/aset/AsetDetail.vue'),
        },

        // Verifikasi Aset
        {
          name: 'VerifikasiAset',
          path: 'vaset',
          component: () => import('@/views/dashboard/pages/verifikasiaset/VerifikasiAset.vue'),
        },
        {
          name: 'VerifikasiAsetDetail',
          path: 'vaset/detail/:id?',
          component: () => import('@/views/dashboard/pages/verifikasiaset/VerifikasiAsetDetail.vue'),
        },

        // Verifikasi Foto
        {
          name: 'VerifikasiFoto',
          path: 'vfoto',
          component: () => import('@/views/dashboard/pages/verifikasifoto/VerifikasiFoto.vue'),
        },

        // Pesanan
        {
          name: 'Pesanan',
          path: 'pesanan',
          component: () => import('@/views/dashboard/pages/pesanan/Pesanan.vue'),
        },
        {
          name: 'PesananDetail',
          path: 'pesanan/detail/:id?',
          component: () => import('@/views/dashboard/pages/pesanan/PesananDetail.vue'),
        },

        // Pesanan Offline
        {
          name: 'PesananOffline',
          path: 'pesananoffline',
          component: () => import('@/views/dashboard/pages/pesananoffline/PesananOffline.vue'),
        },

        // Produksi
        {
          name: 'Produksi',
          path: 'produksi',
          component: () => import('@/views/dashboard/pages/produksi/Produksi.vue'),
        },
        {
          name: 'ProduksiDetail',
          path: 'produksi/detail/:id?',
          component: () => import('@/views/dashboard/pages/produksi/ProduksiDetail.vue'),
        },

        // Trapoin
        {
          name: 'Trapoin',
          path: 'trapoin',
          component: () => import('@/views/dashboard/pages/trapoin/Trapoin.vue'),
        },

        // TopUp Trapoin
        {
          name: 'TopUpTrapoin',
          path: 'topuptrapoin',
          component: () => import('@/views/dashboard/pages/topuptrapoin/TopUpTrapoin.vue'),
        },

        // Dompet
        {
          name: 'Dompet',
          path: 'dompet',
          component: () => import('@/views/dashboard/pages/dompet/Dompet.vue'),
        },

        // Tagihan
        {
          name: 'Tagihan',
          path: 'tagihan',
          component: () => import('@/views/dashboard/pages/tagihan/Tagihan.vue'),
        },

        // Tagihan
        {
          name: 'Penarikan',
          path: 'penarikan',
          component: () => import('@/views/dashboard/pages/penarikan/Penarikan.vue'),
        },

        // User
        {
          name: 'User',
          path: 'user',
          component: () => import('@/views/dashboard/pages/user/User.vue'),
        },

        // PBL (Persentase Biaya Layanan)
        {
          name: 'PBL',
          path: 'pbl',
          component: () => import('@/views/dashboard/pages/pbl/Pbl.vue'),
        },

        // Tiket
        {
          name: 'Tiket',
          path: 'tiket',
          component: () => import('@/views/dashboard/pages/tiket/Tiket.vue'),
        },

        // Pengaturan Peran User
        {
          name: 'PeranUser',
          path: 'peranuser',
          component: () => import('@/views/dashboard/pages/peranuser/PeranUser.vue'),
        },

        // Pengaturan Peran Mitra
        {
          name: 'PeranMitra',
          path: 'peranmitra',
          component: () => import('@/views/dashboard/pages/peranmitra/PeranMitra.vue'),
        },

        // Setting
        {
          name: 'Setting',
          path: 'setting',
          component: () => import('@/views/dashboard/pages/setting/Setting.vue'),
          meta: {
            titleBar: 'Setting',
          },
        },

        // Notifikasi
        {
          name: 'Notification',
          path: 'notifikasi',
          component: () => import('@/views/dashboard/pages/notification/Notification.vue'),
          meta: {
            titleBar: 'Notifikasi',
          },
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login') {
    if (localStorage.getItem('token') === null) {
      next({ name: 'Login' })
      return
    }

    axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
    axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')
    axios.post('/v1/g/checkAuth').then((res) => {
      if (res.data.status === 200) {
        next()
      }
    }).catch((e) => {
      localStorage.clear()
      next({ name: 'Login' })
    })
  } else {
    next()
  }
})

export default router
