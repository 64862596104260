<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',
  }
</script>

<style>
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    font-weight: bold;
    font-size: 16px !important;
    white-space: nowrap;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    font-weight: 500;
    white-space: nowrap;
  }
</style>

<style lang="scss">
  tbody {
     tr:hover {
        background-color: transparent !important;
     }
  }

  tbody {
    font-family: Lato;
    font-size: 16px;
    color: #808080;
  }

  tbody {
    thead {
      font-family: Lato;
      font-size: 16px;
      font-weight: bold;
      color: #808080;
    }
  }
</style>
