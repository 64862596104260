import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import qs from 'query-string'
import './filters'
import * as VueGoogleMaps from 'vue2-google-maps'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import 'vue-select/dist/vue-select.css'
import VueTinySlider from 'vue-tiny-slider'
import 'tiny-slider/src/tiny-slider.scss'

import vueNumeralFilterInstaller from 'vue-numeral-filter'

import vCustomSelect from 'vue-select'

import './scss/main.scss'

Vue.use(vueNumeralFilterInstaller, { locale: 'en-gb' })

Vue.component('v-custom-select', vCustomSelect)

Vue.component('tiny-slider', VueTinySlider)

Vue.config.productionTip = false

Vue.prototype.$qs = qs

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBj1nMV0Hsty4ACOvxG3jLkgQQfR20YahQ',
    libraries: 'places',
  },
})

const toastOptions = {
  position: 'top-right',
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false,
}

Vue.use(Toast, toastOptions)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
